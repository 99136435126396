import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  { path: 'employees', loadChildren: './employees/employees.module#EmployeesPageModule' },
  { path: 'log-in', loadChildren: './log-in/log-in.module#LogInPageModule' },
  { path: 'employees/:id', loadChildren: './employee/employee.module#EmployeePageModule' },
  { path: 'employees/:id/edit', loadChildren: './edit-employee/edit-employee.module#EditEmployeePageModule' },
  { path: 'company', loadChildren: './company/company.module#CompanyPageModule' },
  { path: 'company/edit', loadChildren: './edit-company/edit-company.module#EditCompanyPageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'orders', loadChildren: './orders/orders.module#OrdersPageModule' },
  { path: 'orders/:id', loadChildren: './order/order.module#OrderPageModule' },
  { path: 'settlements', loadChildren: './settlements/settlements.module#SettlementsPageModule' },
  { path: 'invoices', loadChildren: './invoices/invoices.module#InvoicesPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
