import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../settings.service';
import { PopoverController } from '@ionic/angular';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
})
export class CurrencySelectorComponent implements OnInit {

  currencies;

  constructor(private settings: SettingsService, public popoverController: PopoverController, private afDb: AngularFireDatabase) {
    this.currencies = afDb.list('config/currencies').snapshotChanges().pipe(map(currencies => currencies.map(currency => ({
      ...currency.payload.val(),
      key: currency.key
    }))));
  }

  ngOnInit() {}

  setCurrency(currency) {
    this.settings.currency = currency;
    this.popoverController.dismiss();
  }

}
