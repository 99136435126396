import { Component } from '@angular/core';

import { Platform, ModalController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LogInPage } from './log-in/log-in.page';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'analytics'
    },
    {
      title: 'Employees',
      url: '/employees',
      icon: 'people'
    },
    {
      title: 'Company',
      url: '/company',
      icon: 'home'
    },
    {
      title: 'Orders',
      url: '/orders',
      icon: 'list'
    },
    {
      title: 'Settlements',
      url: '/settlements',
      icon: 'document'
    },
    {
      title: 'Invoices',
      url: '/invoices',
      icon: 'document'
    }
  ];

  user;
  company;
  companyRef;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private modalController: ModalController,
    private afAuth: AngularFireAuth,
    private afDb: AngularFireDatabase,
    public alertController: AlertController
  ) {
    this.initializeApp();
    afAuth.user.subscribe(res => {
      if (res) {
        this.user = afDb.object('users/' + res.uid).valueChanges();

        this.user.subscribe(user => {
          this.companyRef = afDb.object('companies/' + user.company);

          this.company = this.companyRef.valueChanges();

          this.company.subscribe(async company => {
            if (!company.employees[res.uid] || !company.employees[res.uid].roles || !company.employees[res.uid].roles.admin) {
              await this.afAuth.auth.signOut();

              window.location.reload();
            }
          });
        });

      } else {
        if (!this.user) {
          this.logIn();
        }
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async logIn() {
    const modal = await this.modalController.create({
      component: LogInPage,
      backdropDismiss: false
    });

    await modal.present();
  }

  async logOut() {

    const alert = await this.alertController.create({
      header: 'Log out?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
          }
        }, {
          text: 'Yes ',
          handler: async () => {
            await this.afAuth.auth.signOut();
            window.location.reload();
          }
        }
      ]
    });
    await alert.present();
  }
}
