import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { present } from '@ionic/core/dist/types/utils/overlays';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.page.html',
  styleUrls: ['./log-in.page.scss'],
})
export class LogInPage implements OnInit {

  credentials = {
    email: '',
    password: ''
  };

  constructor(private modalController: ModalController, private afAuth: AngularFireAuth, private loadingController: LoadingController, public alertController: AlertController) { }

  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  async logIn() {
    const loading = await this.loadingController.create();
    loading.present();
    await this.afAuth.auth.signInWithEmailAndPassword(this.credentials.email, this.credentials.password).then(res => {
      this.dismissModal();
    }).catch(error => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      this.presentAlert(errorMessage);
    });
    loading.dismiss();
  }

  async presentAlert(msg) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: msg,
      buttons: ['OK']
    });
  
    await alert.present();
  }
}
